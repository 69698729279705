const defaultTheme = {
  /* Alterem as cores aqui de acordo com a prototipagem */
  colors: {
    "base-white": "#DEE1F4",
    "second-white": "#A8A8B3",
    "line-white": "rgba(255, 255, 255, 0.2)",
    "white-span": "#B3B0B8",
    "base-black": "#1E1E21",
    "second-black": "#1F2B53",
    "third-black": "#000012",
    "fourth-black": "#09090B",
    "fifth-black": "#121214",
    "base-shape": "#1B1B1B",
    "base-blue": "#38BCDE",
    "second-blue": "#182240",
    "third-blue": "#73E5E2",
    "fourth-blue": "#010027",
    "fifth-blue": "#0072ED",
    "sixth-blue": "#090C30",
    "base-grey": "#737380",
    "second-grey": "#505057",
    "third-grey": "rgba(255,255,230,0.6)",
    "fourth-grey": "#222222",
    "fifth-grey": "#7C7C8A",
    "sixth-grey": "#A9A9B2",
    "gradient-blue": "linear-gradient(to bottom, #73E5E2, #3D66BC)",
    "base-green": "#04D361",
    "opacity-green": "rgba(4,211,97, 20%)",
    "gradient-green": "linear-gradient(to bottom, #04D361, #0F3924)",
    "base-red": "#CE4A4A",
    "second-red": "#ED6CA4",
    "third-red": "#FF3333",
    "fourth-red": "#D5232D",
    "gradient-red": "linear-gradient(to bottom, #ED6CA4, #A43E6A)",
    "second-gradient-red": "linear-gradient(to bottom, #CE4A4A, #b43131)",
    sdc: {
      background: "#000205",
    },
    articles: {
      "base-input": "#010027",
      "base-background": "#182240",
      "base-profile": "#0B1B2B",
      "base-post": "#010027",
      "base-border": "#a6abc77f",
      "base-label": "#737380",
      "base-span": "#e1e1e6",
      "base-text": "#737380",
      "base-subtitle": "#e1e1e6",
      "base-title": "#e1e1e6",
      "brand-hover": "#3294F8",
      "external-link": "#73E5E2",
    },
  },
  fonts: {
    regular: "'Lexend Variable'",
    alt: "'Inter Variable'",
    sdc: "'Bai Jamjuree'",
  },
  textSizes: {
    "text-title-xl": "3rem",
    "text-title-l": "2rem",
    "text-title-m": "1.5rem",
    "text-title-s": "1.2rem",
    "text-title-xs": "1.125rem",
    "text-regular-l": "1.25rem",
    "text-bold-l": "1.25rem",
    "text-regular-m": "1rem",
    "text-bold-m": "1rem",
    "text-regular-s": "0.875rem",
    "text-regular-xs": "0.775rem",
    "text-bold-s": "0.75rem",
    articles: {
      "title-title-l": "1.5rem",
      "title-title-m": "1.25rem",
      "title-title-s": "1.125rem",
      "text-text-m": "1rem",
      "text-text-s": "0.875rem",
      "components-link": "0.75rem",
    },
  },
};

export default defaultTheme;
